/* Wrapper for the entire chatbot including the sidebar */
.chatbot-wrapper {
    display: flex;
    height: 100vh;
    background: #f8f9fa;
    font-family: Arial, sans-serif;
    /*background: linear-gradient(to bottom, hsl(98, 27%, 62%), #ffffff); /* Light blue to white gradient */
    /*width:100%*/
  }

/* Chatbot Wrapper Header */
.chat-header {
  display: flex;
  align-items: flex-start;
  justify-content: right; /* Center the logo and title horizontally */
  padding: 2px;
  background-color: rgba(253, 254, 255, 0.8);;
  /*border-bottom: 3px double #0073e6;*/
  width: 100%;
  position: relative;
  /*z-index: 10; /* Ensure it stays on top */
}


/* Chat Logo Styling */
.chat-logo {
  height: 70px; /* Adjust size of the logo */
  width: auto;
  margin-right: 15px; /* Space between logo and title */
  background:transparent;
}

/* Chat Title Styling */
.chat-title {
  font-size: 24px; /* Adjust the font size */
  font-weight: bold;
  color: #333;
}

.chat-content {
  display: flex;
  flex: 1;
  width: 100%;
}
  
  /* Sidebar styling */
  .sidebar {
    width: 250px; /* Fixed width for sidebar */
    background-color: #f4f4f4;
    padding: 10px;
    border-right: 1px solid #ccc;
    overflow-y: auto;
    /*height: 100vh; /* Make the sidebar span the full height */
    box-sizing: border-box;
    flex-shrink: 0
}
  
  /* Container for chat interface */
/* Adjust the container for chat interface */
.chatbot-container {
    flex: 1; /* Ensure it takes up the remaining space */
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 10px;
    background-color: rgba(253, 254, 255, 0.8);;
    overflow: hidden;
}
  /* Chat area styles */
  .chat-area {
    flex:1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start; 
    align-items: flex-start;
    padding: 10px;
    gap: 10px; 
    height: 100%; 
    overflow-y: auto; 
    width: 100%; 
    box-sizing: border-box; 
  }
  
  .dynamic-button-grid {
    display: grid;
    grid-template-columns: repeat(3, minmax(100px, 1fr)); /* 3 columns that stretch based on content */
    gap: 2px; /* Adjust spacing between buttons */
    width: 50%; /* Ensure the grid takes the full width */
    margin-bottom: 20px; /* Space below the grid */
    /*justify-items: stretch; /* Allows buttons to stretch to fill the available space */
    height: 200px;

}

/* Styling for individual buttons */
.dynamic-button {   
    padding: 0px; /* Adjust padding as needed */
    background-color: #dcedc8;
    border: 1px solid #d1d1d1;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    /*width: 200px; /* Set a fixed width for uniform button size */
    height: 100%; /* Set a fixed height for uniformity */
    width:100%;
    text-align: center;
    word-break: break-word; /* Allow text wrapping if necessary */
    box-sizing: border-box; /* Ensure padding doesn't affect button size */
    justify-content:right; /* Center text horizontally */
    align-items: center; /* Center text vertically */
    font-size:16px;
    line-height: 1.5; /* Increase line height for more vertical space between lines of text */
    letter-spacing: 0.5px;
    border-radius: 20px; /* Makes the button rounded */
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3);
   ;

}
  
  .dynamic-button:hover {
    background-color: #f0f0f0;
  }



  .sub-prompt-grid {
    display: flex;
    flex-wrap: wrap; /* Allow buttons to wrap to the next line */
    gap: 5px; /* Space between buttons */
    /*justify-content: flex-start; 
    align-self: flex-start;
    text-align:left;
    /*align-items: flex-start;*/ 
    width: 50%; 
    max-width: 500px; /* Control the overall width of the grid */
    /*margin: 0 auto; */
    padding: 1px; /* Optional padding around the grid */
    box-sizing: border-box; /* Ensure padding is included in the grid’s total width */
    
  }
  
  .sub-prompt-button {
    padding: 10px;
    background-color: #dcedc8;
    border: 1px solid #ccc;
    border-radius: 10px;
    cursor: pointer;
    transition: background-color 0.2s, box-shadow 0.2s;
    font-size: 15px;
    text-align: left;
    min-width: 150px;
    line-height: 1.5; /* Increase line height for more vertical space between lines of text */
    letter-spacing: 0.5px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3);
  }
  
  .sub-prompt-button:hover {
    background-color: #f0f0f0;
  }

  
  /* Styling for the input box */
  .input-box {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-top: 1px solid #ccc;
    background-color: #fff;
    width: calc(100% - 250px); /* Full width minus the sidebar width */
    box-sizing: border-box;
    position: fixed; /* Use fixed positioning to keep it at the bottom */
    bottom: 0; /* Stick to the bottom */
    left: 250px; /* Start from the right end of the sidebar */
}
  
  /* Input and button styles */
  .chat-input {
    flex: 1; /* Allow the input to take up all available space */
    padding: 10px;
    border: 4px double rgba(135, 206,250, 0.8);
    border-radius: 20px; /* Rounded borders */
    margin-right: 10px; /* Space between input and buttons */
    outline: none; /* Remove the default outline */
    width: 100%; /* Ensure it spans the available space */
    max-width: 60%; /* Adjust this value as needed to control maximum width */
    box-sizing: border-box; /* Ensure padding doesn't affect total width */
    transition: border-color 0.3s; /* Smooth transition on focus */
    height: 50px;
    text-align: center;
    font-size: 16px;
    letter-spacing: 1.2px;
}

.chat-input:focus {
    border-color: #0073e6; /* Change border color on focus */
}
  
  .send-button, .reset-button {
    padding: 10px 15px;
    background: #0073e6;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-left: 5px; /* Space between buttons */
    transition: background 0.2s;
    
  }
  
  .send-button:hover {
    background: #005bb5;
  }
  
  .reset-button {
    background: #f44336;
  }
  
  .reset-button:hover {
    background: #d32f2f;
  }
  
  
  .message {
    display: flex;
    width: 90%; 
    margin: 5px 0;
    box-sizing: border-box;
}

.message-bubble {
    padding: 10px;
    border-radius: 5px;
    word-wrap: break-word;
    max-width: 100%;
}

/* User message aligned to the center-right */
.message-container.user {
    justify-content: flex-end; /* Push user message to the right */
    text-align: right; /* Right-align the text inside the bubble */
}

/* AI message aligned to the center-left */
.message-container.ai {
    justify-content: flex-start; /* Push AI message to the left */
    text-align: left; /* Left-align the text inside the bubble */
}

.message-container.bot {
    justify-content: flex-start; /* Push AI message to the left */
    text-align: left; /* Left-align the text inside the bubble */
}


.message-container.user .message-bubble {
    background-color: #dcedc8; /* Background for user messages */
    border: 1px solid #a5d6a7;
    text-align: right; /* Text inside the bubble is right-aligned */
}


.message-container.ai .message-bubble {
    /*background-color: #c7caf4; /* Background for AI messages */
    background-color : rgba(135, 206,250, 0.8); /* Light blue with 80% opacity */
    border: 1px solid #ccc;
    text-align: left; /* Text inside the bubble is left-aligned */
}


.message-container.bot .message-bubble {
    /*background-color: #c7caf4; /* Background for AI messages */
    /*background-color: #c7caf4; */
    background-color: rgba(135, 206, 250, 0.8);; /* Light blue with 80% opacity */
    border: 1px solid #ccc;
    text-align: left; /* Text inside the bubble is left-aligned */
}


.message-container {
    display: flex;
    flex-direction: column; /* Arrange message and sub-prompt buttons vertically */
    width: 100%; /* Use full width of the chat area */
    margin: 5px 0;
}

/* Align AI message container to the left */
.message-container.ai {
    align-items: flex-start; /* Align AI messages to the left */
    gap: 10px;
}

/* Align user message container to the right */
.message-container.user {
    align-items: flex-end; /* Align user messages to the right */
}


  .list-item-spacing {
    margin-bottom: 2cqb; /* Adjust the value as needed */
  }